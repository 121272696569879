import React, { useState } from "react";
import { ethers } from "ethers";
import { Modal, Spinner } from "react-bootstrap";
import { FaCheckCircle, FaCopy, FaExclamationCircle } from "react-icons/fa";

function ClaimPage() {
  const [selectedChain, setSelectedChain] = useState("redbelly"); // Default to redbelly
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [showNetworkModal, setShowNetworkModal] = useState(false);
  const [claimResult, setClaimResult] = useState({
    success: false,
    message: "",
  });

  const CHAIN_CONFIG = {
    redbelly: {
      chainId: "0x99", // 153
      chainName: "RedBelly Testnet",
      contractAddress: "0xeeA69cb740081ddf2683Db6aD6915B69C7854c40",
      nativeCurrency: {
        name: "RBNT",
        symbol: "RBNT",
        decimals: 18,
      },
      rpcUrls: ["https://governors.testnet.redbelly.network"],
    },
    arbitrum_sepolia: {
      chainId: "0x66eee", // 421614
      chainName: "Arbitrum Sepolia",
      contractAddress: "0x11b1f7ee41bfe9f43251c382e851578fa5010c97",
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://sepolia-rollup.arbitrum.io/rpc"],
    },
  };

  const handleChainChange = async (chain) => {
    setSelectedChain(chain);

    // Check if wallet is connected
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const network = await provider.getNetwork();
        const targetChainId = parseInt(CHAIN_CONFIG[chain].chainId, 16);

        if (network.chainId !== targetChainId) {
          setShowNetworkModal(true);
        }
      } catch (error) {
        console.error("Error checking network:", error);
      }
    }
  };

  const handleSwitchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: CHAIN_CONFIG[selectedChain].chainId }],
      });
      setShowNetworkModal(false);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [CHAIN_CONFIG[selectedChain]],
          });
          setShowNetworkModal(false);
        } catch (error) {
          console.error("Error adding network:", error);
        }
      }
    }
  };

  const handleClaimTokens = async () => {
    setShowLoadingModal(true);
    let provider;
    try {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);

      // Get current network and check if we need to switch
      const network = await provider.getNetwork();
      const targetChainId = parseInt(CHAIN_CONFIG[selectedChain].chainId, 16);

      if (network.chainId !== targetChainId) {
        try {
          // Try to switch to the network first
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: CHAIN_CONFIG[selectedChain].chainId }],
          });
        } catch (switchError) {
          // If the network doesn't exist, add it
          if (switchError.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [CHAIN_CONFIG[selectedChain]],
            });
          } else {
            throw switchError;
          }
        }
        provider = new ethers.providers.Web3Provider(window.ethereum);
      }

      const signer = provider.getSigner();
      const address = await signer.getAddress();
      console.log("Wallet address:", address);

      // Create contract interface for the function call
      const abi = [
        "function faucet() external",
        "function getRemainingDailyMintAllowance(address) external view returns (uint256)",
      ];
      const iface = new ethers.utils.Interface(abi);

      // Check allowance first
      const contract = new ethers.Contract(
        CHAIN_CONFIG[selectedChain].contractAddress,
        abi,
        signer
      );
      const allowance = await contract.getRemainingDailyMintAllowance(address);
      console.log("Allowance:", allowance.toString());

      if (allowance.isZero()) {
        throw new Error(
          "Daily claim limit reached. Please try again tomorrow."
        );
      }

      // Add more detailed logging for the faucet call
      console.log("Attempting to call faucet");
      const tx = await contract.faucet({
        gasLimit: 500000,
      });
      console.log("Transaction sent:", tx.hash);

      const receipt = await tx.wait();
      console.log("Transaction receipt:", receipt);

      if (receipt.status === 0) {
        throw new Error(
          "Transaction failed. The contract rejected the operation."
        );
      }

      setClaimResult({
        success: true,
        message: "Successfully claimed 1000 opUSDC tokens!",
        txHash: tx.hash,
      });
    } catch (error) {
      console.error("Detailed error:", {
        error,
        code: error.code,
        message: error.message,
        data: error.data,
        reason: error.reason,
        transaction: error.transaction,
      });

      let errorMessage = "Failed to claim tokens";

      // Enhanced error handling
      if (error.code === 4001) {
        errorMessage = "Transaction rejected by user";
      } else if (error.code === -32603) {
        errorMessage =
          error.data?.message ||
          error.reason ||
          "Transaction failed. Please check your wallet has enough RBNT for gas fees.";
      } else if (error.message.includes("execution reverted")) {
        errorMessage =
          "Transaction failed. Please check if you have enough gas or if the contract is paused.";
      } else {
        errorMessage = error.message || "Unknown error occurred";
      }

      setClaimResult({
        success: false,
        message: errorMessage,
      });
    } finally {
      setShowLoadingModal(false);
      setShowResultModal(true);
    }
  };

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(CHAIN_CONFIG[selectedChain].contractAddress);
    setClaimResult({
      success: true,
      message: "Token address copied to clipboard!",
    });
    setShowResultModal(true);
  };

  return (
    <>
      <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1 mt-3">
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Claim opUSDC Tokens</h4>

                  {/* Add network selection */}
                  <div className="mb-4">
                    <h5>Select Network</h5>
                    <div className="btn-group" role="group">
                      <button
                        className={`btn ${
                          selectedChain === "redbelly"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        onClick={() => handleChainChange("redbelly")}
                      >
                        RedBelly Testnet
                      </button>
                      <button
                        className={`btn ${
                          selectedChain === "arbitrum_sepolia"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        onClick={() => handleChainChange("arbitrum_sepolia")}
                      >
                        Arbitrum Sepolia
                      </button>
                    </div>
                  </div>

                  <p className="card-text mb-4">
                    Here you can claim 1000 test opUSDC tokens to try out the
                    platform features.
                  </p>

                  <button
                    className="btn btn-primary mb-4"
                    onClick={handleClaimTokens}
                  >
                    Claim opUSDC Tokens
                  </button>

                  <div className="mt-4">
                    <h5>Token Information</h5>
                    <p>
                      Add the opUSDC token to your MetaMask wallet using this
                      address on {CHAIN_CONFIG[selectedChain].chainName}:
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <code className="p-2 bg-light rounded">
                        {CHAIN_CONFIG[selectedChain].contractAddress}
                      </code>
                      <button
                        className="btn btn-outline-secondary"
                        onClick={copyAddressToClipboard}
                      >
                        Copy Address
                      </button>
                    </div>
                  </div>

                  <div className="mt-4">
                    <h5>Important Notes:</h5>
                    <ul>
                      <li>You can claim tokens once per day</li>
                      <li>
                        These are test tokens for demonstration purposes only
                      </li>
                      <li>Make sure you're connected to the correct network</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loading Modal */}
      <Modal
        show={showLoadingModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="text-center py-4">
          <Spinner animation="border" variant="primary" className="mb-3" />
          <h5 className="mb-2">Processing Claim</h5>
          <p className="text-muted mb-0">
            Please wait while we process your request...
          </p>
        </Modal.Body>
      </Modal>

      {/* Result Modal */}
      <Modal
        show={showResultModal}
        onHide={() => setShowResultModal(false)}
        centered
      >
        <Modal.Body className="text-center py-4">
          {claimResult.success ? (
            <FaCheckCircle className="text-success mb-3" size={50} />
          ) : (
            <FaExclamationCircle className="text-danger mb-3" size={50} />
          )}
          <h5 className="mb-2" style={{ color: "#333" }}>
            {claimResult.success ? "Success!" : "Error"}
          </h5>
          <p
            className="mb-3"
            style={{
              color: "#000",
              fontWeight: "500",
              fontSize: "1rem",
            }}
          >
            {claimResult.message}
          </p>
          {claimResult.txHash && (
            <div className="d-flex align-items-center justify-content-center gap-2">
              <small style={{ color: "#666" }}>Transaction Hash:</small>
              <code className="small">
                {claimResult.txHash.slice(0, 10)}...
                {claimResult.txHash.slice(-8)}
              </code>
              <FaCopy
                className="cursor-pointer text-primary"
                onClick={() => {
                  navigator.clipboard.writeText(claimResult.txHash);
                  alert("Transaction hash copied!");
                }}
              />
            </div>
          )}
          <button
            className="btn btn-primary mt-3"
            onClick={() => setShowResultModal(false)}
          >
            Close
          </button>
        </Modal.Body>
      </Modal>

      {/* Add Network Switch Modal */}
      <Modal
        show={showNetworkModal}
        onHide={() => setShowNetworkModal(false)}
        centered
      >
        <Modal.Body className="text-center py-4">
          <FaExclamationCircle className="text-warning mb-3" size={50} />
          <h5 className="mb-2">Network Mismatch</h5>
          <p className="mb-4">
            Please switch your network to{" "}
            {CHAIN_CONFIG[selectedChain].chainName} to continue.
          </p>
          <button className="btn btn-primary" onClick={handleSwitchNetwork}>
            Switch Network
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClaimPage;

import React, { useState, useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";
import axios from "axios";
import { ethers } from "ethers";
import PageTitle from "../../Component/Comman/PageTitle";
import Chart from "../../Component/Exchange/Chart";
import OrderBook from "../../Component/Exchange/OrderBook";
import Spot from "../../Component/Exchange/Spot";
import SpotTradStatus from "../../Component/Exchange/SpotTradStatus";
import TradeHistory from "./TradeHistory";

// Add this new component near the top of the file, after imports
const TradeResponseModal = ({ isOpen, onClose, success }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal show d-block"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Trade Status</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <p>
              {success
                ? "Trade executed successfully!"
                : "Trade execution failed"}
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this new component right here in the file
const BasketInfo = ({
  basketDetails,
  baskets,
  selectedBasketId,
  marketData,
}) => {
  const formatNumber = (num) => {
    // Convert to number if it's a string
    const number = Number(num);
    // Truncate to 2 decimal places without rounding
    return Math.floor(number * 100) / 100;
  };

  return (
    <div className="row g-3 mb-3">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">
                  Selected Basket: {basketDetails?.data?.symbol || "Loading..."}
                </h5>
                <p className="mb-0">
                  <strong>
                    Composition:{" "}
                    {basketDetails?.data?.weights
                      ? Object.entries(basketDetails.data.weights).map(
                          ([token, weight], index, arr) => (
                            <span key={token}>
                              {token}: {formatNumber(parseFloat(weight) * 100)}%
                              {index !== arr.length - 1 ? ", " : ""}
                            </span>
                          )
                        )
                      : "Loading..."}
                  </strong>
                </p>
                <p className="mb-0">
                  <strong>
                    Price: ${formatNumber(marketData?.lastPrice || 0)} USDC
                  </strong>
                </p>
                <small className="text-muted">
                  Last Rebalance:{" "}
                  {new Date(
                    basketDetails?.data?.lastRebalanceDate
                  ).toLocaleDateString() || "N/A"}
                </small>
              </div>
              <div>
                <select
                  className="form-select"
                  value={selectedBasketId}
                  disabled
                >
                  {baskets.map((basket) => (
                    <option key={basket.id} value={basket.id}>
                      {basket.symbol}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Trade Page
function Exchange() {
  const { getToken } = useAuth();
  const [baskets, setBaskets] = useState([]);
  const [basketDetails, setBasketDetails] = useState(null);
  const HARDCODED_BASKET_ID = "1aec9f6c-1e06-4c18-b0f5-0d43d4fbccec";
  const [selectedBasketId] = useState(HARDCODED_BASKET_ID);
  const [depositAmount, setDepositAmount] = useState("");
  const [optimaWalletAddress, setOptimaWalletAddress] = useState(null);
  const [userBalances, setUserBalances] = useState({
    usdc: "0",
    basket: "0",
  });
  const [marketData, setMarketData] = useState({
    bestBid: "0",
    bestAsk: "0",
    lastPrice: "0",
    highPrice24h: "0",
    lowPrice24h: "0",
    volume24h: "0",
  });
  const [trades, setTrades] = useState([]);
  const [isBalanceLoading, setIsBalanceLoading] = useState(true);
  const [showTradeResponse, setShowTradeResponse] = useState(false);
  const [tradeSuccess, setTradeSuccess] = useState(false);

  const fetchBasketDetails = async (basketId) => {
    try {
      const token = await getToken({ template: "optima" });
      const response = await axios.get(
        `https://optima-api-r2o2v.ondigitalocean.app/baskets/${basketId}/overview`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Basket details response:", response.data);
      setBasketDetails(response.data);
    } catch (error) {
      console.error("Error fetching basket details:", error);
    }
  };

  {
    /* Fetching baskets */
  }
  useEffect(() => {
    const fetchBaskets = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await axios.get(
          "https://optima-api-r2o2v.ondigitalocean.app/baskets",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const basketsData = response.data.data || [];
        setBaskets(basketsData);
        console.log("Baskets response:", response.data);
      } catch (error) {
        console.error("Error fetching baskets:", error);
        setBaskets([]);
      }
    };
    fetchBaskets();
  }, [getToken]);

  {
    /* Fetching optima wallet address */
  }
  useEffect(() => {
    const fetchOptimaWallet = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await fetch(
          "https://optima-api-r2o2v.ondigitalocean.app/users/account",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("User account data:", data);
        setOptimaWalletAddress(data.data.wallet.wallet_address);
      } catch (error) {
        console.error("Error fetching Optima wallet:", error);
      }
    };

    fetchOptimaWallet();
  }, [getToken]);

  // Update WebSocket connection to use secure WSS
  useEffect(() => {
    const ws = new WebSocket(
      `wss://bitgo-microservice-nipyw.ondigitalocean.app/baskets/${HARDCODED_BASKET_ID}/prices/stream`
    );

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "PRICE_UPDATE") {
        setMarketData((prevData) => ({
          ...prevData,
          bestBid: data.data.bid.toFixed(2),
          bestAsk: data.data.ask.toFixed(2),
          lastPrice: data.data.bid,
        }));
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.close();
    };
  }, []);

  // Add function to fetch user credits
  const fetchUserCredits = async () => {
    try {
      const token = await getToken({ template: "optima" });
      const response = await axios.get(
        "https://optima-api-r2o2v.ondigitalocean.app/users/credits",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setUserBalances({
          usdc: parseFloat(response.data.data.usd_human).toFixed(2),
          basket:
            response.data.data.basket_human[
              "1aec9f6c-1e06-4c18-b0f5-0d43d4fbccec"
            ] || "0",
        });
      }
    } catch (error) {
      console.error("Error fetching user credits:", error);
    }
  };

  // Add useEffect to fetch credits
  useEffect(() => {
    // Set initial loading state
    setIsBalanceLoading(true);

    // Set timer to remove loading state after 2 seconds
    const loadingTimer = setTimeout(() => {
      setIsBalanceLoading(false);
    }, 2000);

    fetchUserCredits();
    const interval = setInterval(fetchUserCredits, 30000);

    // Clean up both the interval and the timer
    return () => {
      clearInterval(interval);
      clearTimeout(loadingTimer);
    };
  }, []);

  // Add useEffect to fetch basket details when component mounts
  useEffect(() => {
    fetchBasketDetails(HARDCODED_BASKET_ID);
  }, []);

  // Add this new effect to fetch trades
  useEffect(() => {
    const fetchTrades = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await axios.get(
          "https://optima-api-r2o2v.ondigitalocean.app/trades",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTrades(response.data.data || []);
      } catch (error) {
        console.error("Error fetching trades:", error);
      }
    };

    fetchTrades();
    // Refresh trades every 3 seconds
    const interval = setInterval(fetchTrades, 3000);
    return () => clearInterval(interval);
  }, [getToken]);

  return (
    <>
      <TradeResponseModal
        isOpen={showTradeResponse}
        onClose={() => setShowTradeResponse(false)}
        success={tradeSuccess}
      />

      <div className="body-header d-flex py-2 mb-2">
        <div className="container-xxl">
          <div
            className="border-bottom d-flex py-2 mb-2"
            style={{ color: "#002040FF" }}
          >
            <h4>Trade</h4>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        {/* Add the new BasketInfo component here */}
        <BasketInfo
          basketDetails={basketDetails}
          baskets={baskets}
          selectedBasketId={selectedBasketId}
          marketData={marketData}
        />

        {/* Chart and trading components with consistent spacing */}
        {/* <div className="row g-3 mb-3">
          <div className="col-md-12" style={{ padding: "10px" }}>
            <Chart
              basketName={basketDetails?.data?.name || "Loading..."}
              basketDetails={basketDetails}
              marketData={marketData}
            />
          </div>
        </div> */}
        <div className="row g-3 mb-3">
          <div className="col-xxl-12" style={{ padding: "10px" }}>
            <Spot
              balances={userBalances}
              marketData={marketData}
              selectedBasketId={selectedBasketId}
              basketDetails={basketDetails}
              isLoading={isBalanceLoading}
              onTradeResponse={(success) => {
                setTradeSuccess(success);
                setShowTradeResponse(true);
              }}
            />
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-xxl-12" style={{ padding: "10px" }}>
            <TradeHistory trades={trades} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Exchange;

import React, { useState } from "react";
import img1 from "../../../assets/images/landingpage/img1.jpg";
import img7 from "../../../assets/images/landingpage/img7.jpg";
import img5 from "../../../assets/images/landingpage/img5.jpg";

const BlogSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const blogPosts = [
    {
      image: img1,
      date: "29 Oct 2022",
      author: "Muthus Morkar",
      title: "Investing in Crypto: Simplifying a Complex Landscape",
      content: "Crypto investing is often more complicated than it should be.",
      fullContent: `
        <h2>Understanding Cryptocurrency Investment</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        
        <h3>Key Investment Strategies</h3>
        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        
        <h3>Risk Management</h3>
        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
        
        <blockquote>
          "The key to successful cryptocurrency investment lies in understanding both the technology and the market dynamics."
        </blockquote>
        
        <h3>Market Analysis</h3>
        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>`,
      delay: "0.4s",
    },
    {
      image: img7,
      date: "29 Oct 2022",
      author: "Paren Lisba",
      title: "Investing in Crypto: Simplifying a Complex Landscape",
      content: "Crypto investing is often more complicated than it should be.",
      delay: "0.6s",
    },
    {
      image: img5,
      date: "29 Oct 2022",
      author: "Antol Smarty",
      title: "Investing in Crypto: Simplifying a Complex Landscape",
      content: "Crypto investing is often more complicated than it should be.",
      delay: "0.8s",
    },
  ];

  const Modal = ({ post, onClose }) => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "12px",
          width: "90%",
          maxWidth: "800px",
          maxHeight: "90vh",
          overflow: "auto",
          position: "relative",
          padding: "2rem",
        }}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
            border: "none",
            background: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
            color: "#4a5568",
          }}
        >
          ×
        </button>

        {/* Article content */}
        <div>
          <img
            src={post.image}
            alt={post.title}
            style={{
              width: "100%",
              height: "300px",
              objectFit: "cover",
              borderRadius: "8px",
              marginBottom: "1.5rem",
            }}
          />

          <div style={{ marginBottom: "1rem" }}>
            <span style={{ color: "#718096", marginRight: "1rem" }}>
              <i className="fa fa-clock-o me-2"></i>
              {post.date}
            </span>
            <span style={{ color: "#718096" }}>
              <i className="fa fa-user me-2"></i>
              {post.author}
            </span>
          </div>

          <h2
            style={{
              color: "#2d3748",
              marginBottom: "1.5rem",
              fontSize: "2rem",
            }}
          >
            {post.title}
          </h2>

          <div
            dangerouslySetInnerHTML={{ __html: post.fullContent }}
            style={{
              color: "#4a5568",
              lineHeight: "1.8",
              "& h2": {
                color: "#2d3748",
                marginTop: "2rem",
                marginBottom: "1rem",
                fontSize: "1.5rem",
              },
              "& h3": {
                color: "#2d3748",
                marginTop: "1.5rem",
                marginBottom: "1rem",
                fontSize: "1.25rem",
              },
              "& p": {
                marginBottom: "1rem",
              },
              "& blockquote": {
                borderLeft: "4px solid #4299e1",
                paddingLeft: "1rem",
                margin: "2rem 0",
                fontStyle: "italic",
                color: "#2d3748",
              },
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <section
      id="blog"
      className="blog-section section py-5"
      style={{
        backgroundColor: "#f8fafc",
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: "1200px", margin: "0 auto", marginBottom: "200px" }}
      >
        {/* Back button */}
        <div className="row mb-5">
          <div className="col-12 d-flex justify-content-end">
            <a
              href="/"
              className="btn btn-primary px-4"
              style={{
                backgroundColor: "#4a5568",
                border: "none",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <i className="fa fa-arrow-left me-2"></i>Back to Home
            </a>
          </div>
        </div>

        {/* Header Section */}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <div className="section-title">
              <h2
                className="wow fadeInUp display-4 fw-bold mb-4"
                data-wow-delay=".4s"
                style={{
                  color: "#2d3748",
                  fontSize: "2.5rem",
                  marginBottom: "1rem",
                }}
              >
                Latest Insights and Updates
              </h2>
              <p
                className="wow fadeInUp lead"
                data-wow-delay=".6s"
                style={{
                  color: "#4a5568",
                  maxWidth: "800px",
                  margin: "0 auto",
                  fontSize: "1.1rem",
                  lineHeight: "1.6",
                }}
              >
                Explore our Blog for exclusive articles on the latest investment
                strategies, in-depth research, expert advices, and weekly market
                updates
              </p>
            </div>
          </div>
        </div>

        {/* Blog Cards */}
        <div className="row g-4 justify-content-center">
          {blogPosts.map((post, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-12 wow fadeInUp"
              data-wow-delay={post.delay}
            >
              <div
                className="card h-100"
                style={{
                  border: "none",
                  borderRadius: "12px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  backgroundColor: "white",
                  overflow: "hidden",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-5px)";
                  e.currentTarget.style.boxShadow =
                    "0 6px 12px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 6px rgba(0, 0, 0, 0.1)";
                }}
              >
                <div style={{ overflow: "hidden" }}>
                  <img
                    src={post.image}
                    alt={post.title}
                    style={{
                      height: "250px",
                      width: "100%",
                      objectFit: "cover",
                      transition: "transform .3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  />
                </div>
                <div className="card-body d-flex flex-column p-4">
                  <div className="mb-3">
                    <span className="me-3" style={{ color: "#718096" }}>
                      <i className="fa fa-clock-o me-2"></i>
                      {post.date}
                    </span>
                    <span style={{ color: "#718096" }}>
                      <i className="fa fa-user me-2"></i>
                      {post.author}
                    </span>
                  </div>
                  <h4 className="mb-3">
                    <a
                      href="#"
                      style={{
                        color: "#2d3748",
                        textDecoration: "none",
                        fontSize: "1.25rem",
                        fontWeight: "600",
                      }}
                    >
                      {post.title}
                    </a>
                  </h4>
                  <p style={{ color: "#4a5568", flex: "1" }}>{post.content}</p>
                  <div className="mt-3">
                    <button
                      onClick={() => {
                        setSelectedPost(post);
                        setShowModal(true);
                      }}
                      className="btn btn-outline-primary w-100"
                      style={{
                        borderRadius: "6px",
                        padding: "0.5rem 1rem",
                        transition: "all 0.3s ease",
                        border: "1px solid #4299e1",
                        backgroundColor: "transparent",
                        color: "#4299e1",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#4299e1";
                        e.target.style.color = "white";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent";
                        e.target.style.color = "#4299e1";
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showModal && selectedPost && (
        <Modal
          post={selectedPost}
          onClose={() => {
            setShowModal(false);
            setSelectedPost(null);
          }}
        />
      )}
    </section>
  );
};

export default BlogSection;

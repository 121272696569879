import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import flag from "../../assets/images/flag/GB.png";
import Profile from "../../assets/images/profile_av.svg";
import P1 from "../../assets/images/xs/avatar1.svg";
import P2 from "../../assets/images/xs/avatar3.svg";
import P3 from "../../assets/images/xs/avatar5.svg";
import P4 from "../../assets/images/xs/avatar6.svg";
import P5 from "../../assets/images/xs/avatar7.svg";
import F1 from "../../assets/images/flag/GB.png";
import F2 from "../../assets/images/flag/DE.png";
import F3 from "../../assets/images/flag/FR.png";
import F4 from "../../assets/images/flag/IT.png";
import F5 from "../../assets/images/flag/RU.png";
import QrCode from "../../assets/images/qr-code.png";
import { Link } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import { UserProfile } from "@clerk/clerk-react";
import { UserButton } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import { ethers } from "ethers";
import { Modal } from "react-bootstrap";
import axios from "axios";

const ARBITRUM_SEPOLIA_RPC = "https://sepolia-rollup.arbitrum.io/rpc";
const MOCK_OPTIMA_USD_ADDRESS = "0x11b1f7ee41bfe9f43251c382e851578fa5010c97";

const REDBELLY_RPC = "https://governors.testnet.redbelly.network";
const REDBELLY_OPTIMA_USD_ADDRESS =
  "0xeeA69cb740081ddf2683Db6aD6915B69C7854c40";

function Header(props) {
  const { onModalOpen, GotoChangeMenu } = props;
  const [collapse, setCollapse] = useState(false);
  const [collapsebig, setCollapsebig] = useState(false);
  const [sidebarMini, setSidebarMini] = useState(false);
  const { signOut } = useClerk();
  const { isSignedIn, isLoaded, getToken } = useAuth();
  const { user } = useUser();
  const [balance, setBalance] = useState("0");
  const [isVerified, setIsVerified] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const checkToken = async () => {
      if (isSignedIn) {
        try {
          const token = await getToken({ template: "optima" });
          console.log("Current user token:", token);
        } catch (err) {
          console.error("Error fetching token:", err);
        }
      }
    };

    checkToken();
  }, [isSignedIn, getToken]);

  useEffect(() => {
    const fetchBalance = async () => {
      if (user?.primaryWeb3Wallet) {
        try {
          const provider = new ethers.providers.JsonRpcProvider(REDBELLY_RPC);
          const tokenContract = new ethers.Contract(
            REDBELLY_OPTIMA_USD_ADDRESS,
            ["function balanceOf(address) view returns (uint256)"],
            provider
          );

          const walletBalance = await tokenContract.balanceOf(
            user.primaryWeb3Wallet.web3Wallet
          );
          const formattedBalance = ethers.utils.formatEther(walletBalance);
          setBalance(Number(formattedBalance).toFixed(2));
        } catch (error) {
          console.error("Error fetching balance:", error);
          setBalance("0");
        }
      }
    };

    fetchBalance();
  }, [user]);

  useEffect(() => {
    const checkVerification = async () => {
      if (user?.primaryWeb3Wallet?.web3Wallet) {
        try {
          const token = await getToken({ template: "optima" });
          const response = await axios.get(
            `https://optima-api-r2o2v.ondigitalocean.app/chains/153/kyc/${user.primaryWeb3Wallet.web3Wallet}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data.success) {
            setIsVerified(response.data.data.isVerified);
            setVerificationMessage(response.data.data.message);
            setRedirectUrl(response.data.data.redirectUrl);

            // Show modal if not verified
            if (!response.data.data.isVerified) {
              setShowVerificationModal(true);
            }
          }
        } catch (error) {
          console.error("Error checking verification:", error);
        }
      }
    };

    checkVerification();
  }, [user, getToken]);

  const handleCloseModal = () => {
    setShowVerificationModal(false);
  };

  const handleRedirect = () => {
    window.location.href = redirectUrl;
  };

  if (!isLoaded) {
    return null;
  }

  const closeCommanSidebar = () => {
    var sidebar = document.getElementById("commansidebar");
    if (sidebar) {
      if (sidebar.classList.contains("d-none")) {
        sidebar.classList.remove("d-none");
      } else {
        sidebar.classList.add("d-none");
      }
    }
  };
  return (
    <>
      <div className="header">
        <nav className="navbar py-4">
          <div className="container-xxl">
            <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
              {/* <div className="dropdown notifications me-3">
                <span
                  className="py-2 px-3 font-weight-bold"
                  style={{ color: "#002040FF" }}
                >
                  User Balance ${balance}
                </span>
              </div> */}
              <div className="dropdown notifications">
                <span
                  className="py-2 px-3 font-weight-bold"
                  style={{ color: "#002040FF" }}
                >
                  User Profile
                </span>
              </div>
              <div className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center mx-2">
                <UserButton afterSignOutUrl="/" />
              </div>
            </div>
            <button
              className="navbar-toggler p-0 border-0 menu-toggle order-3"
              type="button"
              onClick={() => {
                var sidebar = document.getElementById("mainsidemenu");
                if (sidebar) {
                  if (sidebar.classList.contains("open")) {
                    sidebar.classList.remove("open");
                  } else {
                    sidebar.classList.add("open");
                  }
                }
              }}
            >
              <span className="fa fa-bars"></span>
            </button>
            <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 d-flex align-items-center"></div>
          </div>
        </nav>
      </div>

      {/* Verification Modal */}
      <Modal
        show={showVerificationModal}
        onHide={handleCloseModal}
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isVerified ? "Verification Status" : "Verification Required"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{verificationMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          {isVerified ? (
            <button className="btn btn-secondary" onClick={handleCloseModal}>
              Close
            </button>
          ) : (
            <>
              <button className="btn btn-secondary" onClick={handleCloseModal}>
                Close
              </button>
              <button className="btn btn-primary" onClick={handleRedirect}>
                Verify Now
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import ConfirmationPopup from "../Ui Component/ConfirmationPopup";
import { useAuth } from "@clerk/clerk-react";

function Spot({
  balances,
  marketData,
  selectedBasketId,
  basketDetails,
  isLoading,
  onTradeResponse,
}) {
  const { getToken } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState({
    action: "",
    type: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);

  // Get basket name from basketDetails
  const basketName = basketDetails?.data?.symbol || "Loading...";

  // Separate states for buy and sell orders, but without price field
  const [buyOrderDetails, setBuyOrderDetails] = useState({
    amount: "",
    total: "",
  });

  const [sellOrderDetails, setSellOrderDetails] = useState({
    amount: "",
    total: "",
  });

  // Add new state for currency selection
  const [spendCurrency, setSpendCurrency] = useState("USDC");
  const [receiveCurrency, setReceiveCurrency] = useState(basketName);

  // Function to switch currencies
  const switchCurrencies = () => {
    setSpendCurrency(receiveCurrency);
    setReceiveCurrency(spendCurrency);
    // Reset amounts when switching
    setBuyOrderDetails({ amount: "", total: "" });
  };

  // Update totals when market data or amounts change
  useEffect(() => {
    // If spending USDC, calculate how much basket we'll receive
    if (spendCurrency === "USDC") {
      setBuyOrderDetails((prev) => ({
        ...prev,
        total: prev.amount
          ? (parseFloat(prev.amount) / parseFloat(marketData.bestAsk)).toFixed(
              8
            )
          : "",
      }));
    }
    // If spending basket, calculate how much USDC we'll receive
    else {
      setBuyOrderDetails((prev) => ({
        ...prev,
        total: prev.amount
          ? (parseFloat(prev.amount) * parseFloat(marketData.bestAsk)).toFixed(
              2
            )
          : "",
      }));
    }
  }, [marketData.bestAsk, buyOrderDetails.amount, spendCurrency]);

  // Add these formatting functions at the top of the component
  const formatNumberWithCommas = (value) => {
    if (!value) return "";
    // Remove existing commas first
    const cleanValue = value.toString().replace(/,/g, "");
    const parts = cleanValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const unformatNumber = (value) => {
    if (!value) return "";
    return value.toString().replace(/,/g, "");
  };

  // Update handleAmountChange function
  const handleAmountChange = (e, side) => {
    let value = unformatNumber(e.target.value);

    // Only proceed if the value is a valid number or empty
    if (value === "" || !isNaN(value)) {
      if (side === "buy") {
        if (spendCurrency === "USDC") {
          setBuyOrderDetails({
            amount: value,
            total: value
              ? (parseFloat(value) / parseFloat(marketData.bestAsk)).toFixed(8)
              : "",
          });
        } else {
          setBuyOrderDetails({
            amount: value,
            total: value
              ? (parseFloat(value) * parseFloat(marketData.bestAsk)).toFixed(2)
              : "",
          });
        }
      }
    }
  };

  const handlePercentageClick = (percentage, side) => {
    if (side === "buy") {
      if (spendCurrency === "USDC") {
        const amount = ((balances.usdc * percentage) / 100).toFixed(2);
        setBuyOrderDetails({
          amount,
          total: (parseFloat(amount) / parseFloat(marketData.bestAsk)).toFixed(
            8
          ),
        });
      } else {
        const amount = ((balances.basket * percentage) / 100).toFixed(8);
        setBuyOrderDetails({
          amount,
          total: (parseFloat(amount) * parseFloat(marketData.bestAsk)).toFixed(
            2
          ),
        });
      }
    }
  };

  const handleShowConfirmation = (action, type) => {
    setConfirmationDetails({ action, type });
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = async () => {
    try {
      setIsProcessing(true);
      const token = await getToken({ template: "optima" });
      const orderType = spendCurrency === "USDC" ? "BUY" : "SELL";
      const amount = buyOrderDetails.amount;

      let endpoint = "https://optima-api-r2o2v.ondigitalocean.app/trades/";
      let payload;

      if (orderType === "BUY") {
        endpoint += "buy";
        payload = {
          stable_amount: parseFloat(amount),
          basket_id: selectedBasketId,
          coin_symbol: "BASKET",
          stable_transfer_id: "test",
        };
      } else {
        endpoint += "sell";
        payload = {
          basket_amount: parseFloat(amount),
          basket_id: selectedBasketId,
          basket_transaction_id: "test",
          platform: "BITGO",
        };
      }

      console.log("Sending request with payload:", payload);

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      console.log("Response status:", response.status);
      console.log("Response data:", responseData);

      if (!response.ok) {
        throw new Error(
          `Trade failed: ${responseData.message || "Unknown error"}`
        );
      }

      // Reset the form after successful trade
      setBuyOrderDetails({ amount: "", total: "" });
      setShowConfirmation(false);

      // Call the onTradeResponse prop with success
      onTradeResponse(true);
    } catch (error) {
      console.error("Trade error:", error);
      // Call the onTradeResponse prop with failure
      onTradeResponse(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const styles = {
    container: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "24px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
    },
    section: {
      border: "1px solid #e0e3eb",
      borderRadius: "12px",
      padding: "20px",
      marginBottom: "16px",
    },
    inputGroup: {
      marginBottom: "24px",
    },
    label: {
      fontSize: "16px",
      fontWeight: "500",
      marginBottom: "12px",
      color: "#333",
    },
    input: {
      width: "100%",
      padding: "16px",
      fontSize: "24px",
      border: "1px solid #e0e3eb",
      borderRadius: "8px",
      outline: "none",
      transition: "border-color 0.2s",
      paddingRight: "120px",
    },
    inputContainer: {
      position: "relative",
      width: "100%",
      marginBottom: "12px",
    },
    currencyButton: {
      position: "absolute",
      right: "8px",
      top: "50%",
      transform: "translateY(-50%)",
      padding: "8px 16px",
      backgroundColor: "#f8f9fa",
      border: "1px solid #e0e3eb",
      borderRadius: "6px",
      cursor: "pointer",
      color: "#333",
      fontSize: "14px",
      fontWeight: "500",
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    dropdownArrow: {
      marginLeft: "4px",
      fontSize: "12px",
    },
    percentageContainer: {
      display: "flex",
      gap: "8px",
      marginTop: "12px",
    },
    percentButton: {
      padding: "6px 12px",
      border: "1px solid #e0e3eb",
      borderRadius: "6px",
      backgroundColor: "transparent",
      color: "#666",
      cursor: "pointer",
      transition: "all 0.2s",
    },
    activePercentButton: {
      backgroundColor: "#f0f3fa",
      color: "#2962ff",
      borderColor: "#2962ff",
    },
    conversionRate: {
      fontSize: "14px",
      color: "#666",
      marginTop: "8px",
    },
    convertButton: {
      width: "100%",
      padding: "16px",
      backgroundColor: "#2962ff",
      color: "white",
      border: "none",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "500",
      cursor: "pointer",
      transition: "background-color 0.2s",
    },
    arrowContainer: {
      display: "flex",
      justifyContent: "center",
      margin: "20px 0",
      color: "#666",
    },
    balanceText: {
      fontSize: "14px",
      color: "#666",
      marginTop: "8px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.section}>
        {/* Spend Section */}
        <div style={styles.inputGroup}>
          <div style={styles.label}>Spend</div>
          <div style={styles.inputContainer}>
            <input
              type="text"
              style={styles.input}
              value={formatNumberWithCommas(buyOrderDetails.amount)}
              onChange={(e) => handleAmountChange(e, "buy")}
              placeholder="0"
            />
            <button style={styles.currencyButton} onClick={switchCurrencies}>
              {spendCurrency === "USDC" ? "USDC" : basketName}
              <span style={styles.dropdownArrow}>▼</span>
            </button>
          </div>
          <div style={styles.balanceText}>
            Balance:{" "}
            {isProcessing
              ? "Loading..."
              : spendCurrency === "USDC"
              ? `${formatNumberWithCommas(balances.usdc)} USDC`
              : `${formatNumberWithCommas(balances.basket)} ${basketName}`}
          </div>
          <div style={styles.percentageContainer}>
            {["10%", "20%", "50%", "100%"].map((text, index) => {
              const percentValue = [10, 20, 50, 100][index];
              const expectedAmount =
                spendCurrency === "USDC"
                  ? ((balances.usdc * percentValue) / 100).toFixed(2)
                  : ((balances.basket * percentValue) / 100).toFixed(8);

              return (
                <button
                  key={text}
                  onClick={() => handlePercentageClick(percentValue, "buy")}
                  style={{
                    ...styles.percentButton,
                    ...(buyOrderDetails.amount === expectedAmount
                      ? styles.activePercentButton
                      : {}),
                  }}
                >
                  {text}
                </button>
              );
            })}
          </div>
        </div>

        {/* Arrow Separator */}
        <div style={styles.arrowContainer}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 4L12 20M12 20L18 14M12 20L6 14"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>

        {/* To Receive Section */}
        <div style={styles.inputGroup}>
          <div style={styles.label}>To receive</div>
          <div style={styles.inputContainer}>
            <input
              type="text"
              style={{ ...styles.input, backgroundColor: "#f8f9fa" }}
              value={formatNumberWithCommas(buyOrderDetails.total)}
              readOnly
              placeholder="0"
            />
            <button
              style={{ ...styles.currencyButton, backgroundColor: "#f8f9fa" }}
              onClick={switchCurrencies}
            >
              {receiveCurrency === "USDC" ? "USDC" : basketName}
              <span style={styles.dropdownArrow}>▼</span>
            </button>
          </div>
          <div style={styles.conversionRate}>
            {spendCurrency === "USDC" ? (
              <>
                1 USDC ≈{" "}
                {formatNumberWithCommas(
                  (1 / parseFloat(marketData.bestAsk)).toFixed(8)
                )}{" "}
                {basketName} (Buy)
              </>
            ) : (
              <>
                1 {basketName} ≈{" "}
                {formatNumberWithCommas(
                  parseFloat(marketData.bestBid).toFixed(2)
                )}{" "}
                USDC (Sell)
              </>
            )}
          </div>
        </div>

        <button
          style={{
            ...styles.convertButton,
            backgroundColor: spendCurrency === "USDC" ? "#00C087" : "#FF3B69",
            opacity:
              !buyOrderDetails.amount || parseFloat(buyOrderDetails.amount) <= 0
                ? 0.5
                : 1,
            cursor:
              !buyOrderDetails.amount || parseFloat(buyOrderDetails.amount) <= 0
                ? "not-allowed"
                : "pointer",
          }}
          onClick={() =>
            handleShowConfirmation(
              spendCurrency === "USDC" ? "BUY" : "SELL",
              basketName
            )
          }
          disabled={
            !buyOrderDetails.amount || parseFloat(buyOrderDetails.amount) <= 0
          }
        >
          {spendCurrency === "USDC" ? "Buy Basket" : "Sell Basket"} {basketName}
        </button>
      </div>

      <ConfirmationPopup
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirm}
        title={`Confirm ${confirmationDetails.action} ${confirmationDetails.type}`}
        body={
          isProcessing ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-2 text-black">Processing your order...</p>
            </div>
          ) : (
            <div className="text-black">
              <p>
                {`Are you sure you want to convert ${buyOrderDetails.amount} ${spendCurrency} to ${buyOrderDetails.total} ${receiveCurrency}?`}
              </p>
            </div>
          )
        }
        confirmButtonDisabled={isProcessing}
        confirmButtonText={isProcessing ? "Processing..." : "Confirm"}
        cancelButtonText={isProcessing ? "OK" : "Cancel"}
      />
    </div>
  );
}

// Add prop types validation if you're using PropTypes
Spot.defaultProps = {
  onTradeResponse: () => {}, // Default empty function if prop is not provided
};

export default Spot;

import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationPopup = ({
  show,
  onHide,
  onConfirm,
  title,
  body,
  confirmButtonDisabled,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton className="text-black">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-black">{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} className="text-black">
          {cancelButtonText}
        </Button>
        {!confirmButtonDisabled && (
          <Button variant="primary" onClick={onConfirm} className="text-black">
            {confirmButtonText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationPopup;

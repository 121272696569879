import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useAuth } from "@clerk/clerk-react";
import { ethers } from "ethers";

function Transaction({ activeTab }) {
  const { getToken } = useAuth();
  const [transactions, setTransactions] = useState([]);

  // Update formatAmount to accept symbol parameter
  const formatAmount = (amount, symbol) => {
    try {
      const regularNumber = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });
      return `${ethers.utils.formatUnits(regularNumber, 18)} ${
        symbol || "BASKET"
      }`;
    } catch (error) {
      console.error("Error formatting amount:", error);
      return "0 BASKET";
    }
  };

  // Custom columns configuration
  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      format: (row) => new Date(row.date).toLocaleString(),
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      cell: (row) => (
        <span
          className={`badge bg-${
            row.type === "Deposit" ? "success" : "warning"
          }`}
        >
          {row.type}
        </span>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      format: (row) => formatAmount(row.amount, row.symbol),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span
          className={`badge bg-${
            row.status === "COMPLETED" ? "success" : "warning"
          }`}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: "Transaction Hash",
      selector: (row) => row.transaction_hash,
      sortable: true,
      cell: (row) =>
        row.transaction_hash ? (
          <a
            href={`https://redbelly.testnet.routescan.io/tx/${row.transaction_hash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary text-truncate d-inline-block"
            style={{ maxWidth: "150px" }}
          >
            {row.transaction_hash.slice(0, 6)}...
            {row.transaction_hash.slice(-4)}
          </a>
        ) : (
          <span>N/A</span>
        ),
    },
  ];

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = await getToken({ template: "optima" });

        const [depositsResponse, withdrawalsResponse] = await Promise.all([
          fetch("https://optima-api-r2o2v.ondigitalocean.app/users/deposits", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(
            "https://optima-api-r2o2v.ondigitalocean.app/users/withdrawals",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);

        const depositsData = await depositsResponse.json();
        const withdrawalsData = await withdrawalsResponse.json();

        // Update deposit formatting
        const formattedDeposits = depositsData.data.map((deposit) => ({
          ...deposit,
          date: deposit.created_at,
          type: "Deposit",
        }));

        // Update withdrawal formatting
        const formattedWithdrawals = withdrawalsData.data.map((withdrawal) => ({
          ...withdrawal,
          date: withdrawal.created_at,
          type: "Withdrawal",
        }));

        // Combine and sort by date (newest first)
        const allTransactions = [
          ...formattedDeposits,
          ...formattedWithdrawals,
        ].sort((a, b) => new Date(b.date) - new Date(a.date));

        setTransactions(allTransactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    // Initial fetch
    fetchTransactions();

    // Set up interval for periodic fetching
    const intervalId = setInterval(fetchTransactions, 3000); // 3000ms = 3 seconds

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [getToken]);

  const filterTransactions = (transactions) => {
    switch (activeTab) {
      case "deposit":
        return transactions.filter(
          (transaction) => transaction.type === "Deposit"
        );
      case "withdraw":
        return transactions.filter(
          (transaction) => transaction.type === "Withdrawal"
        );
      default: // 'balance' tab or any other
        return transactions; // Show all transactions
    }
  };

  return (
    <div className="container-fluid p-4">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header py-3 d-flex justify-content-between">
            <h6 className="mb-0 fw-bold">Recent Transactions</h6>
          </div>
          <div className="card-body">
            <div className="dataTables_wrapper dt-bootstrap5 no-footer">
              <div className="row">
                <div className="col-sm-12">
                  <DataTable
                    columns={columns}
                    data={filterTransactions(transactions)}
                    defaultSortField="date"
                    defaultSortAsc={false}
                    selectableRows={false}
                    highlightOnHover={true}
                    pagination
                    responsive
                    striped
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transaction;

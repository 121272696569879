import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/OptimaLogo.png";
import {
  SignedIn,
  SignedOut,
  useAuth,
  SignUpButton,
  SignInButton,
  UserProfile,
  useUser,
} from "@clerk/clerk-react";
import { useClerk } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { isSignedIn, isLoaded, getToken } = useAuth();
  const { user } = useUser();
  const [showUserProfile, setShowUserProfile] = React.useState(false);

  useEffect(() => {
    const checkToken = async () => {
      if (isSignedIn) {
        try {
          const token = await getToken({ template: "optima" });
          console.log("Current user token:", token);
        } catch (err) {
          console.error("Error fetching token:", err);
        }
      }
    };

    checkToken();
  }, [isSignedIn, getToken]);

  const createUserInBackend = async () => {
    if (!isSignedIn || !user) return;

    try {
      const token = await getToken({ template: "optima" });

      const userData = {
        name: user.firstName || "",
        surname: user.lastName || "",
        email: user.primaryEmailAddress?.emailAddress || "",
        country: user.publicMetadata?.country || "Unknown",
      };

      const response = await fetch(
        "https://optima-api-r2o2v.ondigitalocean.app/users/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("User created successfully:", data);
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const checkUserAccount = async () => {
    if (!isSignedIn || !user) return;

    try {
      const token = await getToken({ template: "optima" });

      const response = await fetch(
        "https://optima-api-r2o2v.ondigitalocean.app/users/account",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      console.log("User account check:", data);

      if (!data.exists) {
        // If user doesn't exist, create them
        await createUserInBackend();
      }
    } catch (error) {
      console.error("Error checking user account:", error);
    }
  };

  useEffect(() => {
    if (isSignedIn && user) {
      checkUserAccount();
    }
  }, [isSignedIn, user]);

  // New component for BitGo wallet section
  const BitGoWalletSection = () => {
    const [balance, setBalance] = useState(null);
    const [walletAddress, setWalletAddress] = useState(null);
    const [showWithdrawForm, setShowWithdrawForm] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [withdrawAddress, setWithdrawAddress] = useState("");

    // Use wallet address from account check and fetch balance from Polygon
    useEffect(() => {
      const fetchPolygonBalance = async (address) => {
        try {
          // Using Polygon mainnet RPC
          const response = await fetch("https://polygon-rpc.com/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              jsonrpc: "2.0",
              method: "eth_call",
              params: [
                {
                  to: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174", // USDC contract on Polygon
                  data: `0x70a08231000000000000000000000000${address.slice(2)}`, // balanceOf(address)
                },
                "latest",
              ],
              id: 1,
            }),
          });

          const data = await response.json();
          const balanceInWei = parseInt(data.result, 16);
          const balanceInUSDC = balanceInWei / 1e6; // USDC has 6 decimals
          setBalance(balanceInUSDC);
        } catch (error) {
          console.error("Error fetching Polygon balance:", error);
        }
      };

      // Get wallet address from account data
      if (walletAddress) {
        fetchPolygonBalance(walletAddress);

        // Set up polling interval
        const intervalId = setInterval(
          () => fetchPolygonBalance(walletAddress),
          60000
        );
        return () => clearInterval(intervalId);
      }
    }, [walletAddress]);

    // Update wallet address when account data changes
    useEffect(() => {
      if (user?.publicMetadata?.wallet?.wallet_address) {
        setWalletAddress(user.publicMetadata.wallet.wallet_address);
      }
    }, [user]);

    const handleWithdraw = async (e) => {
      e.preventDefault();
      try {
        const token = await getToken({ template: "optima" });
        const response = await fetch(
          "https://optima-api-r2o2v.ondigitalocean.app/wallet/withdraw",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount: withdrawAmount,
              address: withdrawAddress,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Withdrawal failed");
        }

        // Reset form
        setWithdrawAmount("");
        setWithdrawAddress("");
        setShowWithdrawForm(false);

        // Refresh balance
        fetchPolygonBalance(walletAddress);
      } catch (error) {
        console.error("Error processing withdrawal:", error);
        alert("Withdrawal failed. Please try again.");
      }
    };

    return (
      <div className="bitgo-wallet-section mb-4">
        <div className="card shadow-sm">
          <div className="card-body p-4">
            <h3 className="mb-4 fw-bold">Your BitGo Wallet</h3>

            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="p-3 bg-light rounded">
                  <label className="text-muted mb-2">Balance</label>
                  <h4 className="mb-0">
                    {balance !== null ? `${balance} USDC` : "Loading..."}
                  </h4>
                </div>
              </div>

              <div className="col-md-6 mb-4">
                <div className="p-3 bg-light rounded">
                  <label className="text-muted mb-2">Deposit Address</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control bg-white"
                      value={walletAddress || "Loading..."}
                      readOnly
                    />
                    <button
                      className="btn btn-outline-primary"
                      onClick={() =>
                        navigator.clipboard.writeText(walletAddress)
                      }
                    >
                      Copy
                    </button>
                  </div>
                  <small className="text-muted mt-2 d-block">
                    Send USDC to this address to deposit funds
                  </small>
                </div>
              </div>
            </div>

            <button
              className="btn btn-primary"
              onClick={() => setShowWithdrawForm(true)}
            >
              Withdraw Funds
            </button>
          </div>
        </div>

        {showWithdrawForm && (
          <div className="withdraw-form mt-4">
            <form onSubmit={handleWithdraw}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-4">Withdraw USDC</h5>
                  <div className="mb-3">
                    <label className="form-label">Amount (USDC)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={withdrawAmount}
                      onChange={(e) => setWithdrawAmount(e.target.value)}
                      required
                      min="0"
                      step="0.01"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Destination Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={withdrawAddress}
                      onChange={(e) => setWithdrawAddress(e.target.value)}
                      required
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      type="submit"
                      className="btn btn-primary flex-grow-1"
                    >
                      Confirm Withdraw
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => setShowWithdrawForm(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <header className="header navbar-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg">
                <a
                  className="navbar-brand fw-bold fs-3"
                  href="/"
                  title="Logo"
                  style={{ marginRight: "25px" }}
                >
                  <img
                    src={logo}
                    alt="logo"
                    width="100"
                    style={{ borderRadius: "10px" }}
                  />
                </a>
                <button
                  className="navbar-toggler mobile-menu-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul
                    id="nav"
                    className="navbar-nav ms-auto"
                    style={{ width: "auto" }}
                  >
                    <li className="nav-item">
                      <a href="#heroarea" className="active">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#feature">Features</a>
                    </li>
                    <li className="nav-item">
                      <a href="#market">Baskets</a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/blog"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        Insights
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#team">About</a>
                    </li>
                    <li className="nav-item" style={{ marginLeft: "20px" }}>
                      {!isSignedIn ? (
                        <SignInButton mode="modal">
                          <a className="text-decoration-none">Login</a>
                        </SignInButton>
                      ) : (
                        <button
                          onClick={() => signOut({ redirectUrl: "/" })}
                          className="text-decoration-none btn btn-link text-white fw-bold"
                          style={{ padding: 0 }}
                        >
                          Log out
                        </button>
                      )}
                    </li>
                    <li className="nav-item">
                      {!isSignedIn ? (
                        <SignUpButton mode="modal">
                          <button className="btn bg-light text-primary py-2 px-3 rounded-pill fw-bold">
                            Sign up
                          </button>
                        </SignUpButton>
                      ) : (
                        <a
                          href="/wallet-page"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn bg-light text-primary py-2 px-3 rounded-pill fw-bold"
                        >
                          dApp
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {showUserProfile && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "930px" }}
          >
            <div className="modal-content">
              <div className="modal-header border-bottom">
                <h5 className="modal-title fw-bold fs-4">Account Settings</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowUserProfile(false)}
                ></button>
              </div>
              <div className="modal-body p-4">
                <div className="clerk-profile-wrapper mb-2">
                  <BitGoWalletSection />
                  <UserProfile />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;

"use client";

import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

function BalanceDetails({ credits, marketData }) {
  const [isLoading, setIsLoading] = useState(true);

  const basketNameMapping = {
    "1aec9f6c-1e06-4c18-b0f5-0d43d4fbccec": "OP 10",
  };

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  // Calculate basket value using lastPrice from marketData
  const calculateBasketValue = (basketAmount) => {
    const basketPrice = Number(marketData?.lastPrice || 0);
    return basketAmount * basketPrice;
  };

  // Calculate total portfolio value
  const getTotalPortfolioValue = () => {
    const usdValue = Number(credits?.data?.usd_human || 0);
    const basketsValue = Object.entries(
      credits?.data?.basket_human || {}
    ).reduce(
      (total, [_, amount]) => total + calculateBasketValue(Number(amount)),
      0
    );
    return usdValue + basketsValue;
  };

  // Add this helper function at the top of the component
  const formatNumber = (num) => {
    // Convert to string and split at decimal point
    const [whole, decimal] = num.toString().split(".");
    // If there's a decimal part, truncate to 2 places without rounding
    if (decimal) {
      return `${whole}.${decimal.slice(0, 2)}`;
    }
    // If no decimal, add .00
    return `${whole}.00`;
  };

  useEffect(() => {
    setOptions({
      chart: {
        height: 250,
        type: "donut",
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
        },
      },
      labels: [
        "USDC",
        ...Object.keys(credits?.data?.basket_human || {}).map(
          (key) => basketNameMapping[key] || key
        ),
      ],
      legend: {
        position: "top",
        horizontalAlign: "center",
        show: true,
      },
      colors: [
        "var(--chart-color1)",
        "var(--chart-color2)",
        "var(--chart-color3)",
        "var(--chart-color4)",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    // Update series to use USD values
    setSeries([
      Number(credits?.data?.usd_human || 0),
      ...Object.entries(credits?.data?.basket_human || {}).map(([_, amount]) =>
        calculateBasketValue(Number(amount))
      ),
    ]);
  }, [credits, marketData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container-fluid p-4">
      <div className="card border-0">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="balance-info h-100 d-flex flex-column py-3">
                <div>
                  <div className="text-muted mb-2">Balance:</div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Asset</th>
                          <th>Amount</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>USDC</td>
                          <td>
                            {isLoading
                              ? "Loading..."
                              : Number(credits?.data?.usd_human || 0).toFixed(
                                  2
                                )}
                          </td>
                          <td>
                            {isLoading
                              ? "Loading..."
                              : `$${Number(
                                  credits?.data?.usd_human || 0
                                ).toFixed(2)}`}
                          </td>
                        </tr>
                        {Object.entries(credits?.data?.basket_human || {}).map(
                          ([key, amount]) => (
                            <tr key={key}>
                              <td>{basketNameMapping[key] || key}</td>
                              <td>{formatNumber(amount)}</td>
                              <td>
                                $
                                {calculateBasketValue(Number(amount)).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          )
                        )}
                        <tr className="border-top">
                          <td>Total</td>
                          <td></td>
                          <td>
                            {isLoading
                              ? "Loading..."
                              : `$${getTotalPortfolioValue().toFixed(2)}`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="chart-container d-flex align-items-center justify-content-center"
                style={{ height: "400px" }}
              >
                <Chart
                  options={options}
                  series={series}
                  type="donut"
                  height="350"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceDetails;

import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";

function Chart({ basketName }) {
  const chartContainerRef = useRef();
  const chartInstanceRef = useRef(null);
  const lineSeriesRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState("D");

  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
    if (!chartInstanceRef.current || !lineSeriesRef.current) return;

    const now = Date.now() / 1000; // Current time in seconds
    let fromTime;

    switch (timeframe) {
      case "1d":
        fromTime = now - 60 * 60; // 1 hour ago
        break;
      case "30d":
        fromTime = now - 30 * 24 * 60 * 60; // 30 days ago
        break;
      case "1m":
        fromTime = now - 60 * 24 * 60 * 60; // 60 days ago
        break;
      case "Y":
      default:
        fromTime = now - 365 * 24 * 60 * 60; // 365 days ago
    }

    chartInstanceRef.current.timeScale().setVisibleRange({
      from: fromTime,
      to: now,
    });

    const seriesData = lineSeriesRef.current.data();
    if (seriesData.length > 1) {
      const visibleData = seriesData.filter(
        (d) => d.time >= fromTime && d.time <= now
      );
      if (visibleData.length > 1) {
        const startPrice = visibleData[0].value;
        const endPrice = visibleData[visibleData.length - 1].value;
        const color = endPrice >= startPrice ? "#26a69a" : "#ef5350";

        lineSeriesRef.current.applyOptions({
          color: color,
        });
      }
    }
  };

  const styles = {
    buttonGroup: {
      display: "flex",
      gap: "2px",
    },
    button: {
      padding: "4px 8px",
      fontSize: "12px",
      border: "1px solid #e0e3eb",
      background: "transparent",
      color: "#787b86",
      cursor: "pointer",
      borderRadius: "4px",
    },
    activeButton: {
      background: "#f0f3fa",
      color: "#2962ff",
      border: "1px solid #e0e3eb",
    },
    header: {
      display: "flex",
      padding: "8px",
      borderBottom: "1px solid #e0e3eb",
      alignItems: "center",
    },
    priceInfo: {
      marginLeft: "12px",
    },
    symbol: {
      color: "#787b86",
    },
    price: {
      marginLeft: "8px",
      color: "#26a69a",
    },
    card: {
      borderRadius: "8px",
      border: "1px solid #e0e3eb",
      backgroundColor: "#ffffff",
      width: "100%",
      height: "100%",
      padding: "25px",
      marginBottom: "30px",
    },
    chartWrapper: {
      width: "80%",
      height: "calc(50vh - 20px)",
      position: "relative",
    },
    chartContainer: {
      width: "100%",
      height: "100%",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#333",
    },
    container: {
      display: "flex",
      gap: "20px",
      width: "100%",
    },
    textSection: {
      width: "20%",
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      border: "1px solid #e0e3eb",
      marginTop: "40px",
    },
    textTitle: {
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "15px",
      color: "#333",
    },
    textContent: {
      fontSize: "14px",
      lineHeight: "1.6",
      color: "#787b86",
    },
    statsGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "30px",
      marginTop: "40px",
    },
    statLabel: {
      color: "#787b86",
      fontSize: "14px",
    },
    statValue: {
      color: "#333",
      fontSize: "14px",
      fontWeight: "500",
    },
    marketStatus: {
      color: "#26a69a",
      display: "flex",
      alignItems: "center",
      gap: "6px",
      fontSize: "14px",
      marginTop: "10px",
    },
    priceChange: {
      color: "#26a69a",
      marginLeft: "8px",
    },
    rangeInfo: {
      marginTop: "40px",
    },
  };

  useEffect(() => {
    console.log("Effect started");
    if (!chartContainerRef.current) {
      console.log("No chart container ref");
      return;
    }

    // Clean up any existing chart instance first
    if (chartInstanceRef.current) {
      chartInstanceRef.current.remove();
      chartInstanceRef.current = null;
    }

    const fetchData = async () => {
      try {
        console.log("Fetching data started");
        const chart = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
          layout: {
            background: { color: "#ffffff" },
            textColor: "#333",
          },
          grid: {
            vertLines: { color: "#f0f3fa" },
            horzLines: { color: "#f0f3fa" },
          },
          rightPriceScale: {
            borderVisible: false,
          },
          timeScale: {
            borderVisible: false,
            timeVisible: true,
          },
        });

        console.log("Chart created");
        chartInstanceRef.current = chart;

        const lineSeries = chart.addLineSeries({
          color: "#26a69a",
          lineWidth: 2,
        });

        lineSeriesRef.current = lineSeries;

        const response = await fetch(
          "https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=365&interval=daily"
        );
        console.log("API response received");
        const data = await response.json();

        const lineData = data.prices
          .map(([timestamp, price]) => ({
            time: Math.floor(timestamp / 1000),
            value: price,
          }))
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.time === item.time)
          )
          .sort((a, b) => a.time - b.time);

        console.log("Setting chart data");
        lineSeries.setData(lineData);

        const startPrice = lineData[0].value;
        const endPrice = lineData[lineData.length - 1].value;
        const initialColor = endPrice >= startPrice ? "#26a69a" : "#ef5350";
        lineSeries.applyOptions({
          color: initialColor,
        });

        console.log("Setting loading to false");
        setIsLoading(false);

        const handleResize = () => {
          chart.applyOptions({
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
          });
        };

        window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
          chart.remove();
        };
      } catch (error) {
        console.error("Error in fetchData:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div style={styles.card}>
        <h2 style={styles.title}>{`${basketName} / USDC`}</h2>
        <div
          style={{
            ...styles.container,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Loading chart data...
        </div>
      </div>
    );
  }

  return (
    <div style={styles.card}>
      <h2 style={styles.title}>{`${basketName} / USDC`}</h2>
      <div style={styles.container}>
        <div style={styles.chartWrapper}>
          <div style={styles.header}>
            <div style={styles.buttonGroup}>
              {["1d", "30d", "1m", "Y"].map((timeframe) => (
                <button
                  key={timeframe}
                  style={{
                    ...styles.button,
                    ...(selectedTimeframe === timeframe
                      ? styles.activeButton
                      : {}),
                  }}
                  onClick={() => handleTimeframeChange(timeframe)}
                >
                  {timeframe}
                </button>
              ))}
            </div>
            <div style={styles.priceInfo}>
              <span style={styles.symbol}>{`${basketName} / USDC`}</span>
              <span style={styles.price}>103,678</span>
              <span style={styles.priceChange}>+477 (+0.47%)</span>
            </div>
          </div>
          <div ref={chartContainerRef} style={styles.chartContainer} />
        </div>

        <div style={styles.textSection}>
          <h3 style={styles.textTitle}>Key Stats</h3>
          <div style={styles.marketStatus}>● Market open</div>
          <div style={styles.statsGrid}>
            <div>
              <div style={styles.statLabel}>Volume</div>
              <div style={styles.statValue}>764.28</div>
            </div>
            <div>
              <div style={styles.statLabel}>Average Volume (30D)</div>
              <div style={styles.statValue}>3.25K</div>
            </div>
            <div>
              <div style={styles.statLabel}>Trading volume 24h</div>
              <div style={styles.statValue}>79.66B</div>
            </div>
            <div>
              <div style={styles.statLabel}>Market capitalization</div>
              <div style={styles.statValue}>2.00T</div>
            </div>
          </div>
          <div style={styles.rangeInfo}>
            <div style={styles.statLabel}>Day's Range</div>
            <div style={styles.statValue}>100,2... - 101,9...</div>
            <div style={styles.statLabel}>52WK Range</div>
            <div style={styles.statValue}>38,5... - 103,6...</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chart;

import React from "react";

function TradeHistory({ trades }) {
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Recent Transactions</h5>
      </div>
      <div className="card-body">
        {trades && trades.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-hover align-middle mb-0">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Amount (USD)</th>
                  <th>Basket Amount</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {trades.map((trade) => (
                  <tr key={trade.id}>
                    <td>
                      <span
                        className={`badge ${
                          trade.type === "BUY" ? "bg-success" : "bg-danger"
                        }`}
                      >
                        {trade.type}
                      </span>
                    </td>
                    <td>${parseFloat(trade.stable_amount).toFixed(2)}</td>
                    <td>{parseFloat(trade.basket_amount).toFixed(4)}</td>
                    <td>
                      <span
                        className={`badge ${
                          trade.status === "COMPLETED"
                            ? "bg-success"
                            : "bg-warning"
                        }`}
                      >
                        {trade.status}
                      </span>
                    </td>
                    <td>{new Date(trade.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-4">
            <p className="mb-0">There are no records to display</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TradeHistory;
